<template>
  <div class="head-line">
    <div class="header">
      <img src="@/assets/images/IA/img_nav_logo.png" alt="logo" class="header-logo ">
      <div class="user-info-container">
        <router-link to="/" class="btn-go-home">
          <img src="@/assets/images/IA/icon_home.png" alt="icon">
        </router-link>
        <p class="divide">I</p>
        <router-link to="/therapist/login" class="therapist-login">
          치료사 로그인
        </router-link>
        <p class="divide">I</p>
        <router-link to="/user/login" class="patient-login">
          환자 로그인
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderWithLogin"
}
</script>

<style scoped>
.head-line {
  border-bottom: 1px solid #E1E3E6;
}

.header {
  background: #FFFFFF;
  height: 80px;
  padding: 0 25px 0 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 1140px;
  margin: auto;
}

.header-logo {
  width: 190px;
  height: 48px;
}

.header p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 36px;
  color: #000000;
  margin: 0;
  cursor: pointer;
}

.user-name {
  cursor: default !important;
}

.header strong {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #999999;
  margin: 0;
}

.user-info-container {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  align-items: center;
}

.divide {
  color: #E1E3E6 !important;
  width: 13px;
  text-align: center;
  margin: 0 10px !important;
}


.btn-go-home {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.btn-go-home img {
  width: 20px;
  height: 20px;
}

.
.btn-go-home p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
  margin: 0 15px 0 5px;
}

.therapist-login {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #0069CA;

}

.patient-login {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #00ABEB;
}

</style>
