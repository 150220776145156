<template>
  <div>
    <HeaderWithLogin/>
    <div class="view-layout">
      <router-view></router-view>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import HeaderWithLogin from "@/components/HeaderWithLogin";

export default {
  name: "CommonView",
  components: {HeaderWithLogin, Footer}
}
</script>

<style scoped>
.view-layout {
  width: 1140px;
  margin: 0 auto;
}
</style>
